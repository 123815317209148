
import OImage from "@/components/base/image/OImage"
import Button from "@/components/base/Button"
import UserAvatar from "@/components/user/UserAvatar"

import mappingService from "@/services/mapping.service"
import { mdiChevronLeft } from "@mdi/js"

export default {
    name: "ChatProfileCard",
    components: { OImage, Button, UserAvatar },
    props: {
        /** User to be displayed having the values
         *    name: String,
         *    contact_person: {profile_picture: Image}
         */
        user: {
            type: Object,
            default: () => ({}),
        },
        /** link to school or business */
        link: {
            type: [String, Object],
            default: undefined,
        },
        /** disable the clickable profile card regardless of whether is link prop provided or not */
        noLink: {
            type: Boolean,
            default: false,
        },
        /** if "< zurück" button is shown */
        showBackBtn: {
            type: Boolean,
            default: false,
        },
        showGender: {
            type: Boolean,
            default: false,
        },
        isTyping: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            mdiChevronLeft,
        }
    },
    computed: {
        isLink() {
            if (!this.$can.admin()) {
                const business = this.user?.business
                if (
                    business &&
                    !business.permissions?.includes("publish_business")
                ) {
                    return false
                }
            }

            return !!this.link && !this.noLink
        },
        name() {
            if (!this.user.public_contact_person) return this.user.name
            const gender = mappingService.mapGender(
                this.user.public_contact_person.gender
            )
            if (this.showGender) {
                return `${gender} ${this.user.public_contact_person.firstname} ${this.user.public_contact_person.lastname}`
            }
            return `${this.user.public_contact_person.firstname} ${this.user.public_contact_person.lastname}`
        },
        subtitle() {
            if (this.user.public_contact_person) return this.user.name
            return ""
        },
        clickOnAvatarAndNames() {
            return this.showBackBtn ? "click" : null
        },
        clickOnProfileCard() {
            return this.showBackBtn ? null : "click"
        },
    },
    methods: {
        onLinkClick() {
            if (this.isLink) {
                this.$router.push(this.link)
            }
        },
    },
}
