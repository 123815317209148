export const displayedUser = {
    id: 1722,
    name: "Umbrella",
    profile_picture: {
        id: 9141,
        is_private: false,
        // TODO: adjust image path
        file_name: "622f4a8583640",
        file_path: "/storage/images/622f4a8583640/",
        urls: {
            "0.webp":
                "https://i.oabat.de/71ARTcrvfdjdxitjgpZpc_xVReMk2ycuWgFmwKGpdsQ/resize:fit:2000:2000:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.webp",
            "1.webp":
                "https://i.oabat.de/XqVdkyGMDexXXRDzDFeTLvBQO7_lTzh4cB94koJO-oA/resize:fit:1000:1000:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.webp",
            "2.webp":
                "https://i.oabat.de/-oqpSiHqeq9CxI7woRR1Xa4d_Wh6PpIBT3yMGeS3KXU/resize:fit:500:500:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.webp",
            "3.webp":
                "https://i.oabat.de/kvIvT4AgvI0dBrU-etT1_6ifNfX_ZfCdeN6A1KgNIZI/resize:fit:200:200:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.webp",
            "p.webp":
                "https://i.oabat.de/Z5FXZV76K8Lu3BAehzs0xma7wTy8lzwO86mFv5VuSEw/resize:fit:100:100:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.webp",
            "job.webp":
                "https://i.oabat.de/xs9SnA8GVAjj_wLypwmorVG7EcqwlwWl3HjXh_kmJUU/resize:fill-down:370:412:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.webp",
            "job@2.webp":
                "https://i.oabat.de/NWLSSx8T8fdVj2o97ERYKJUM74vYGeXB1W156i5bQA4/resize:fill-down:740:824:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.webp",
            "bus.webp":
                "https://i.oabat.de/koW21gBy_6jaCNl0R11tOHOt2Z-eh8AeWpfiA_TgYOg/resize:fill-down:370:180:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.webp",
            "bus@2.webp":
                "https://i.oabat.de/30RDTSe-vYdVTxx5qcOB8LHCCVQKhu0xqCswDzzKI5c/resize:fill-down:740:360:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.webp",
            "800_fit.webp":
                "https://i.oabat.de/ArztQJ5m0Z--65SPgbpjIH37TQRiGs8nRufsk-Yvtp4/resize:fill-down:800:800:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.webp",
            "400_fit.webp":
                "https://i.oabat.de/4h_jh5gFEL82aT_brp5tU1PZD6E7bxyjndZXKOyI_Z8/resize:fill-down:400:400:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.webp",
            "200_fit.webp":
                "https://i.oabat.de/HFU4IN82hy4X5mOGbGVvMv8KKaHjP6aFhAcBuF_mAoQ/resize:fill-down:200:200:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.webp",
            "job_detail.webp":
                "https://i.oabat.de/RSsx1ey7WFernns_oW6nSCSH0n-hwmrI7jzBhM6-DLI/resize:fill-down:800:1067:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.webp",
            "job_detail@2.webp":
                "https://i.oabat.de/ROYsqUCH5C9FJgtHMShpbbMJ5ckTJ6FkGY0BoJFz5LY/resize:fill-down:1600:2134:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.webp",
            "0.jpg":
                "https://i.oabat.de/BCRJNbjtxvfnGVgPXjAB16QEdnX41GkKfEviOQQk2rk/resize:fit:2000:2000:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.jpg",
            "1.jpg":
                "https://i.oabat.de/6kJLNTw3n5MmbjHZ5Z0YVZSlpAIxWl_Zz8gNzL3Z1t8/resize:fit:1000:1000:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.jpg",
            "2.jpg":
                "https://i.oabat.de/rycDht6jrXlRP_O1PvbNYc298Zu57k2tJGJLWBNfU_8/resize:fit:500:500:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.jpg",
            "3.jpg":
                "https://i.oabat.de/rIrYulUXb17WeJ1Ab6GYlGyE3pltsAtDMsG6xG9xVCk/resize:fit:200:200:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.jpg",
            "p.jpg":
                "https://i.oabat.de/LE7ziRKZTGDVYWN79maBwnkCDgqmwSYbAyDhurKsUt8/resize:fit:100:100:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.jpg",
            "job.jpg":
                "https://i.oabat.de/73clOuyzo2O-2zViiymSSqcJcane05foeLnBfkW3uQI/resize:fill-down:370:412:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.jpg",
            "job@2.jpg":
                "https://i.oabat.de/-A0DiYL_pKxxhfMYr0YDqdJPyd8XtvXfRXbbQhIHkJU/resize:fill-down:740:824:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.jpg",
            "bus.jpg":
                "https://i.oabat.de/y5ZIbVHuzP2Fwj6l-hhWGzigqGC32sgYjHqrCVJ8x-A/resize:fill-down:370:180:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.jpg",
            "bus@2.jpg":
                "https://i.oabat.de/1RjpS5xR7d_PyOhI6GUKy2ATKJRxwP6EUKB_P_Oku5c/resize:fill-down:740:360:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.jpg",
            "800_fit.jpg":
                "https://i.oabat.de/sYn9U-Cu_CLI2wvaADTaaLSCsNEkdsgX_iHjFUj0zA0/resize:fill-down:800:800:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.jpg",
            "400_fit.jpg":
                "https://i.oabat.de/zMo-1r3BUSo-WF_xjUoa1xdZ4tLQRhGgZEf-8N-XnTM/resize:fill-down:400:400:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.jpg",
            "200_fit.jpg":
                "https://i.oabat.de/IZ-Q202PPWRlUavs7V-exDdwtMU0zfGl0TJZV6LXgQI/resize:fill-down:200:200:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.jpg",
            "job_detail.jpg":
                "https://i.oabat.de/pCZkrjjWATS-UhoBLsSshDOa67ECm5PzijJhmpakoVs/resize:fill-down:800:1067:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.jpg",
            "job_detail@2.jpg":
                "https://i.oabat.de/_dCaqxeVQGUST699b8Kv5hvmDHFN_y8xOUeM6ZNRz8U/resize:fill-down:1600:2134:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM1MC9kb3dubG9hZA.jpg",
        },
    },
    profile_url: "/betriebe/oabat",
    public_contact_person: {
        id: 187,
        firstname: "Annette",
        lastname: "Birkin",
        gender: "Frau",
        profile_picture: {
            id: 8499,
            is_private: false,
            // TODO: adjust image path
            urls: {
                "0.webp":
                    "https://i.oabat.de/5HERMDy4-XIcYeAXKhZS1qA5F6U3hengXPnHfdLPDS8/resize:fit:2000:2000:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.webp",
                "1.webp":
                    "https://i.oabat.de/cY-b3hH3vfxC8VaOsVLJXrAtDw1esXKffr3bokCq8hM/resize:fit:1000:1000:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.webp",
                "2.webp":
                    "https://i.oabat.de/hIxgivdmTmOu2PHBMbrOkeIskhtRS5LbWze0P2JW7ps/resize:fit:500:500:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.webp",
                "3.webp":
                    "https://i.oabat.de/PJKcFQcPrOk9heBQ6SXI84MhLL6CghaeQJtTn2cUOEs/resize:fit:200:200:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.webp",
                "p.webp":
                    "https://i.oabat.de/_28nfoemKO6aJCX9K593hzP8pFL5K4AVLfbYU5UniKc/resize:fit:100:100:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.webp",
                "job.webp":
                    "https://i.oabat.de/SKMcumKjU-l986_dEsBJIfRDgVJ6NPOSNnfEsNoUKI4/resize:fill-down:370:412:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.webp",
                "job@2.webp":
                    "https://i.oabat.de/9UzIcTn_qJ8ukcqJUve9CbGwaiehlM9wwuDaPzCG9d0/resize:fill-down:740:824:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.webp",
                "bus.webp":
                    "https://i.oabat.de/1sRY-thXuzaJTeV9O7zIUAfBJpargEOW1lnUddF8qAM/resize:fill-down:370:180:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.webp",
                "bus@2.webp":
                    "https://i.oabat.de/ma4ZJ2kgecIEnUoTgnB0iPbFyqiEto4L8oa4yGdyJog/resize:fill-down:740:360:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.webp",
                "800_fit.webp":
                    "https://i.oabat.de/rQhJ4cspT3Kf-gXmtxRDHj40gCsNDelXoBHS4-sZ8z0/resize:fill-down:800:800:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.webp",
                "400_fit.webp":
                    "https://i.oabat.de/EFKPm_I9B1KHMRqDZViJEnQGRxSFOo0baqFRvbozHUY/resize:fill-down:400:400:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.webp",
                "200_fit.webp":
                    "https://i.oabat.de/1F65X4nZbQKRWfaX6VaWsquuDMAX7WhDiQsw0xvtlm8/resize:fill-down:200:200:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.webp",
                "job_detail.webp":
                    "https://i.oabat.de/Yf_esrlqvo65grFah0jdvAuBSWh5zdVI4kzILQr-PVw/resize:fill-down:800:1067:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.webp",
                "job_detail@2.webp":
                    "https://i.oabat.de/TKEuuVkP361sl80q4bRMvTLu7DXMSg65INXMWg6GsR8/resize:fill-down:1600:2134:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.webp",
                "0.jpg":
                    "https://i.oabat.de/1KnHYHNZmo7-QjTX9onltS2FP2zwcrskmblL4ILDVv0/resize:fit:2000:2000:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.jpg",
                "1.jpg":
                    "https://i.oabat.de/G412eB1JVhlz7_B_yfupKHRodHtLYWUU17m2UOtf0MI/resize:fit:1000:1000:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.jpg",
                "2.jpg":
                    "https://i.oabat.de/yO5cEsVMxzJK3K6M2c4VpIb_pes0S9ODgr2h_kvStx8/resize:fit:500:500:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.jpg",
                "3.jpg":
                    "https://i.oabat.de/Fef4QTvZVn1IeXBka9oIKgGxhJ5q_E_LeefYRX9N2fk/resize:fit:200:200:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.jpg",
                "p.jpg":
                    "https://i.oabat.de/OvRk9mWUfN_h0VudoRJMpoxGghfaH2dfCvQjT01r95c/resize:fit:100:100:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.jpg",
                "job.jpg":
                    "https://i.oabat.de/9D4DuiNhpHtSjkGecumoL7YVaZ7zvB5U8I-g03-kQDI/resize:fill-down:370:412:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.jpg",
                "job@2.jpg":
                    "https://i.oabat.de/yjTmAqyxOvv3FUkRJ4Ayw3XBqOvOn72jdCQ1kUF9mQ8/resize:fill-down:740:824:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.jpg",
                "bus.jpg":
                    "https://i.oabat.de/7heCMn9CSV9fLxj7dy5LOuGu8IeyauOs9ye2OIVfpKQ/resize:fill-down:370:180:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.jpg",
                "bus@2.jpg":
                    "https://i.oabat.de/O001HtnUhK3QciU-Hcw050_8xZe4gjWKLysG4rr7Jmw/resize:fill-down:740:360:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.jpg",
                "800_fit.jpg":
                    "https://i.oabat.de/SLr9Y3asigMJnbMcEdR_Y7uOzlkKUlr1oyzuEkE9FEs/resize:fill-down:800:800:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.jpg",
                "400_fit.jpg":
                    "https://i.oabat.de/2e3VAy6oySzKuTTL165YPbhuEDIWyDnd8Nq1MNFYpqQ/resize:fill-down:400:400:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.jpg",
                "200_fit.jpg":
                    "https://i.oabat.de/RgzP3JUsqQvABqJrzZQ11Q1c8UHr7-Si8QBCo7raGfo/resize:fill-down:200:200:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.jpg",
                "job_detail.jpg":
                    "https://i.oabat.de/n4GnCJeVAUELXb9Ryx3ISrZO8MSKsrsVA6SlrPJaFt4/resize:fill-down:800:1067:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.jpg",
                "job_detail@2.jpg":
                    "https://i.oabat.de/HTkcPUbe0k2lRLmbe9wYC_PKCzv_-vgbZTkkvkt3XJY/resize:fill-down:1600:2134:0/background:ffffff/gravity:sm/aHR0cHM6Ly93d3cub2FiYXQuZGUvYXBpL3YyL2ltYWdlcy80ODM0OS9kb3dubG9hZA.jpg",
            },
        },
    },
}

export const messages = [
    {
        body: "Hi, ich suche einen Ausbildungsplatz ab dem 01.09. – habt ihr noch Plätze frei?",
        sender_id: 0,
        type: "regular",
    },
    {
        body: "Hi Mark, wir haben noch einen freien Platz ab dem 01.09! Schickst du mir deinen Lebenslauf?",
        sender_id: 1,
        type: "regular",
    },
    {
        body: null,
        sender_id: 0,
        type: "file",
        file: {
            id: -1,
            file_path: "files/622f774805584/622f774805587.jpg",
            file_size: 1162087,
            type: null,
            mime_type: "image/jpeg",
            original_filename: "Lebenslauf.jpg",
            is_shared: false,
            uuid: "11111111-1111-1111-1111-111111111111",
            is_pending: false,
        },
    },
    {
        body: "Sieht gut aus! Hast du nächste Woche Zeit für ein Vorstellungsgespräch?",
        sender_id: 1,
        type: "regular",
    },
]
