
import ChatProfileCard from "@/components/chat/ChatProfileCard"
import ChatMessagesContainer from "@/components/chat/ChatMessagesContainer"
import ChatInput from "@/components/chat/input/ChatInput"
import { messages, displayedUser } from "@/components/chat/chatMessages"
import { OABALYTICS_INDEX_CLICK } from "@/enums/oabalytics"

export default {
    name: "ChatDemo",
    components: { ChatInput, ChatMessagesContainer, ChatProfileCard },
    data: () => ({
        messages: [],
        selfId: 0,
        displayedUser: displayedUser,
        messageQueue: messages,
        messageInterval: null,
    }),
    mounted() {
        this.startMessageQueue()
    },
    destroyed() {
        clearInterval(this.messageInterval)
    },
    methods: {
        startMessageQueue() {
            setTimeout(() => {
                this.messageInterval = setInterval(() => {
                    this.pushNextMessage()
                }, 2000)
            }, 500)
        },
        pushNextMessage() {
            if (this.messageQueue.length > 0) {
                const message = this.messageQueue.shift()
                this.pushMessage(message)
            } else {
                clearInterval(this.messageInterval)
            }
        },
        pushMessage(message) {
            this.messages.push({
                created_at: new Date().toISOString(),
                visible_to_sender: 1,
                can_delete: false,
                can_edit: false,
                preview: false,
                file: message.file,
                body: message.body,
                type: message.type,
                sender_id: message.sender_id ?? null,
            })
        },
        trackClick(params) {
            this.$oabalytics.trackEvent(OABALYTICS_INDEX_CLICK, params)
        },
    },
}
